.rem-test-hdr {
  padding-top: 30px;
  flex-direction: column;
}

.rem-test-graph {
  height: 400px;
  .highcharts-figure {
    width: 100%;
  }
}

.rem-test-card {
  width: 45%;
  display: inline-block;
  padding: 15px;
}

.rem-test-graph-text {
  background-color: #fffdf5;
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: flex-end;
  button {
    margin-top: 15px;
  }
}

.rem-test-diagrams {
  > div {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  .rem-test-graph-abs {
    width: 100%;
  }
}

.rem-test-abs-diagram {
  .rem-test-abs-diagram-image {
    width: 100%;
  }
}
.rem-test-hdr {
  .remotet {
    margin-top: 20px;
    font-size: 14px;
    div {
      padding: 10px;
      width: 152px;
      margin-right: 20px;
      box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
      border-radius: 30px;
      label {
        gap: 30px;
      }
    }
  }
}

.rem-test-graph.rem-test-graph-abs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .nav-icons {
    &.disable-left {
      pointer-events: none;
      opacity: 0.7;
    }
    &.disable-right {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  .large-charts {
    width: 80%;
    position: relative;
    .modal-close{
      position: absolute;
      top: 15px;
      right: 5px;
      z-index: 3;
    }
  }
}

@media screen and (min-width: 1400px) {
  .rem-test-graph-text {
    font-size: 16px;
  }
}
