@import url("../../../pages/FaultTrendAnalytics/index.scss");

.cause-item {
  &-left {
    width: 70%;
  }
  &-right {
    width: 30%;
  }
}

.main_on_demand {
  padding: 0px 30px;
  padding-bottom: 20px;
}
.main_on_demand_prog {
  padding: 0px 30px;
  padding-bottom: 20px;
}

.service-help {
  &-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
  background-color: #e3f5e3;
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  font-weight: 600;
  .btn-primary {
    font-size: 10px;
    width: auto;
    padding: 5px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1100px) {
  .service-help {
    font-size: 16px;
  }
}
