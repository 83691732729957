.diagnostics-topdtc-complaints {
  padding: 30px 30px;
}

.first-container {
  display: flex;
  grid-gap: 80px;
  padding-top: 20px;
}

.second-container {
  display: flex;
  padding-top: 58px;
  grid-gap: 80px;
}

.individual-vehicle-health-data button {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.individual-vehicle-button {
  padding-left: 80px;
  margin-top: 16px;
}

.individual-vehicle-button button {
  margin-left: 13px;
}

.vin-modal {
  .model-container input {
    width: 300px;
    // font-family: 'Open Sans';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
  }
  .modal-container.small {
    padding-top: 0;
  }
  .modal-close img {
    position: relative;
    top: 4px;
    left: 15px;
  }
}
.save-vin-number-button {
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 20px;
  height: 34px;
  background: #112236;
  border: 1px solid #112236;
  box-shadow: 0px 8px 28px -6px rgb(24 39 75 / 12%),
    0px 18px 88px -4px rgb(24 39 75 / 14%);
  border-radius: 5px;
  color: white;
}

.close-button {
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 20px;
  width: 72px;
  height: 34px;
  border: 1px solid #112236;
  box-shadow: 0px 8px 28px -6px rgb(24 39 75 / 12%),
    0px 18px 88px -4px rgb(24 39 75 / 14%);
  border-radius: 5px;
}

.warranty-rate {
  padding: 30px 60px;

  .first-container {
    display: flex;
    grid-gap: 80px;
  }
}
