.tabs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 60px;
}

.tabs {
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 6px;
  align-content: center;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(100, 116, 139, 0.12);
}
@media screen and (min-width: 1100px) {
  .tabs-container {
    padding: 0px 30px;
  }
}
@media screen and (min-width: 1700px) {
  .tabs-container {
    padding: 0px 60px;
  }
}
