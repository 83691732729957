.maintenance-performance{
    margin-top: 30px;
    padding: 15px 40px;
    background: #FDF9EA;
    border: 1px solid #FCF1CC;
    border-radius: 2px;
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            text-transform: capitalize;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.location-table{
    padding: 30px 0 0;

    thead, th {
        background-color: #11223673;
        color:#fff;
        width: 25% !important; 
        padding: 10px;
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}