.prescribed_actions {
  .sensor {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  .actions_table {
    table {
      padding: 0px;
      thead {
        background: rgba(17, 34, 54, 0.45);
        tr {
          background: rgba(17, 34, 54, 0.45);
          th {
            background: rgba(17, 34, 54, 0.45);
            color: #fff;
            width: 33%;
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
