.routine-row {
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  line-height: 20px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #eeffee;
  align-items: center;
  > div {
    width: 15%;
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4) {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .row-message {
        width: 80%;
      }

      .end-arrow {
        width: 20px;
        transform: rotate(180deg);
      }
    }
  }
  &-hdr {
    background-color: #fcf1cd;
    box-shadow: 0px 8px 28px -6px rgb(24 39 75 / 12%),
      0px 18px 88px -4px rgb(24 39 75 / 14%);
    font-weight: 600;
  }
  &-critical {
    background-color: #fff5f3;
  }
}

.vertical-filler-wrap {
  width: 12px;
  height: 18px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
}

.measure-val {
  font-size: 10px;
  text-align: center;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrap {
  width: 84px;
  height: 4px;
  background-color: #f23732;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}

.slider-value-rep {
  height: 4px;
  width: 56px;
  background-color: #56a64b;
}

.slider-val {
  padding-left: 10px;
}

.slider-pointer {
  width: 1px;
  height: 30px;
  border: 1px dashed #f23732;
  position: absolute;
  top: -13px;
}

.routine-maintenance-right-arrow {
  float: right;
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 4px -2px rgb(24 39 75 / 12%),
    0px 4px 4px -2px rgb(24 39 75 / 8%);
  background: #c4c4c4;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
}

.routine-maintainance-content {
  ul {
    padding: 0;
  }
}

.maintainance-on-demand-filter {
  margin-top: 20px;
  width: 100%;
  padding: 0 60px;
  justify-content: flex-end;
  .filter-category {
    padding-left: 10px;
  }
}
@media screen and (min-width: 1700px) {
  .routine-maintainance-content {
  }
}
