.graph-name {
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #353536;
}

.chart-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  .details-wrapper {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-right: 5px;
    background-color: #e3f5e3;
    justify-content: space-between;
    .chart-details {
      font-size: 10px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .value {
        margin-right: 5px;
        &.green {
          color: #a9d18e;
        }
        &.yellow {
          color: #fdfd62;
        }
        &.red {
          color: #f23732;
        }
      }
    }
  }
  .green-legend,
  .yellow-legend,
  .red-legend,
  .pink-legend {
    text-align: left;
    margin-top: 5px;
    margin-right: 5px;
  }
  .pink-legend {
    .color-box.pink {
      background-color: #f23732;
    }
  }
}

.axis-legend {
  text-align: left;
}
.x-axis-legend {
  text-align: right;
}
.y-axis-legend {
  text-align: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 180px;
  white-space: break-spaces;
}

.y-axis-arrow {
  display: block;
  width: 170px;
  height: 10px;
  background: url("./../../../assets/arrow-yaxis.svg") 100% 100% no-repeat;
}
