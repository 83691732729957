@import "../../../../scss/colors";

.onboard_page_wrapper {
  padding: 0px !important;
}

.onboard-container {
  margin-top: 30px;
  position: relative;
}
.onboard-dtc-container {
}

.onboard-dtc-right-section {
  //   background-color: $card-light-purple;
}

.onboard-dtc-hdr {
  padding-left: 30px;
  font-size: 18px;
  font-weight: 600;
}

.onboard-dtc-type {
  //   transform: rotate(270deg);
  //   width: 345px;
  padding: 15px 30px 10px;
  font-size: 14px;
}

.onboard-dtc-card {
  background-color: $card-purple;
  padding: 15px 30px;
  .onboard-dtc-param {
    background-color: $white;
    padding: 12px 30px;
    box-shadow: $dtcCardBoxShadow;
    border-radius: 4px;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.onboard-dtc-abs-wrap {
  background-color: $card-light-pink;
}
.onboard-dtc-history-wrap {
  background-color: $card-light-red;
}
.onboard-dtc-right-wrap {
  justify-content: space-between;
  width: 80%;
  width: -webkit-fill-available;
}
.onboard-dtc-description,
.onboard-dtc-update-date {
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
}

.dtc-category-container {
  div {
    display: flex;
  }
  &.current {
    background-color: #f1f6ff;
  }
  &.pending {
    background-color: #f4fff4;
  }
  &.historical {
    background-color: #fff4f5;
  }
}

.onboard-dtc-wrap.transmission {
  .onboard-dtc-card {
    background-color: #f4eefd;
  }
}
.onboard-dtc-wrap.abs {
  .onboard-dtc-card {
    background-color: #fdfaf1;
  }
}

.onboard-dtc-description {
  max-width: 22rem;
  min-width: 22rem;
  font-size: 14px;
}

.onboard-dtc-details {
  align-items: center;
  button {
    background-color: $primary;
    color: $white;
    padding: 10px 15px;
  }
}

.onboard-filter {
  justify-content: flex-end;
  margin-top: 30px;
  position: absolute;
  right: 10px;
  top: -20px;
}
@media screen and (min-width: 1400px) {
  .onboard-dtc-type {
    font-size: 16px;
  }
  .onboard-dtc-description {
    font-size: 16px;
  }
  .onboard-dtc-description,
  .onboard-dtc-update-date {
    font-size: 16px;
  }
}
