.maintenance-log-summary-wrapper {
  padding: 20px 30px;

  .tabs-container {
    padding: 0px 20px;
    .tabs {
      padding: 10px 10px;
      width: 200px;
      margin-bottom: 30px;
      font-size: 14px;
    }
  }

  .maintenance-score-container {
    padding: 20px 7.5rem;
    background-color: #fcf1cc;
    margin-bottom: 25px;
    border-radius: 6px;
    .score-graph-container {
      width: 45%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      .score-graph {
        width: 130px;
        height: 130px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .score-details-container {
      width: 55%;
      font-size: 14px;
      color: #353536;
      display: inline-block;
      vertical-align: top;
      .score-text {
        width: 100%;
        text-align: center;
        font-weight: 600;
        margin-bottom: 35px;
      }
      .score-details-ul {
        font-weight: 400;
        margin: 0;
        padding: 0;
        .score-details-li {
          list-style-type: none;
          text-decoration: none;
          margin-top: 5px;
          margin-bottom: 10px;
          .score-param,
          .score-seperator,
          .score-value {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
          .score-param {
            text-align: left;
            width: 40%;
          }
          .score-seperator {
            text-align: center;
            width: 10%;
          }
          .score-value {
            text-align: left;
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1100px) {
}

@media screen and (min-width: 1700px) {
  .tabs-container {
    .tabs {
      font-size: 16px;
    }
  }
}
