.prognostics-based-table, .diagnostics-based-table, .usage-based-table{
    padding: 0;

    thead, th {
        background-color: #11223673;
        color:#fff;
        width: 16% !important; 
        padding: 10px;
        &:last-child{
            width: 20% !important;
        }
    }

    .view-details-from-table{
        cursor:pointer;
        margin-left:5px;
        text-decoration: underline;
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}

.table-title{
    font-weight: 600;
    font-size: 16px;
    color: #112236;
    margin: 30px 0 15px 0;
    text-align: left;
}
