$small: 300px;
$medium: 900px;
.breadcrumb-container {
  position: relative;
  left: 30px;
  top: -20px;
  display: inline-block;
  text-transform: capitalize;
  span a {
    color: #27374a;
    text-decoration: none;
    font-weight: 500;
  }
}

@media screen and (min-width: 1700px) {
  .breadcrumb-container {
    left: 60px;
  }
}
