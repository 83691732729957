$primary: #112236;
$secondary: #fff;
$white: #fff;
$black: #000;
$disabled: #d9dbdf;
$selected: #fcf1cd;
$error: #f23732;
$light-gray: #f9f9fa;
$light-gray-box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
  0px 4px 4px -2px rgba(24, 39, 75, 0.08);

// Font variables
$font12: 12px;
$font14: 14px;
$font18: 18px;

// Boxshadow
// $boxShadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
//   0px 18px 88px -4px rgba(24, 39, 75, 0.14);

$boxShadow: 0px 2px 6px rgba(100, 116, 139, 0.12);

$dtcCardBoxShadow: 0px 1px 10px -2px rgb(24 39 75 / 12%),
  0px 4px 8px -4px rgb(24 39 75 / 14%);

// Pages colours
$card-light-purple: #f1f6ff;
$card-purple: #f4eefd;
$card-light-pink: #fdfaf1;
$card-light-red: #fff4f5;
