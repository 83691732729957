.ishikawa-wrapper{
    overflow: auto;
}
.ishikawa-diagram {
    width: 130%;
    height: 800px;
    padding: 30px 0px;
    .label-0 {
        font-size: 18px;
        fill: #1C3474
    }
    .label-1 {
        font-size: 16px;
        fill: #5071D2;
    }
    .label-2 {
        font-size: 14px;
        fill: rgb(81, 41, 6);
    }
    .label-3 {
        font-size: 12px;
        fill: rgb(2, 96, 90);
    }
    .label-4 {
        font-size: 10px;
        fill: #666;
    }
    .link-0 {
        stroke: #D27025;
        stroke-width: 2px;
    }
    .link-1 {
        stroke: #D27025;
        stroke-width: 1px;
    }
    .link-2,
    .link-3,
    .link-4 {
        stroke: #D27025;
        stroke-width: 0.5px;
    }

    #arrow{
        fill: #D27025;
    }

    .node{
        &:has(.label-0) {
            outline: 1px solid #D27025;
            outline-offset: 5px;
        }
    }
}

.problem{
    text-align: center;
    margin-top: 30px;
    font-size: 24px;
}

.problem-list-ul{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .problem-list{
        text-decoration: none;
        list-style: none;
        text-transform: capitalize;
        margin-left: 5px;
        margin-bottom: 10px;
    }
}
