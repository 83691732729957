@import "colors";
@import "style";
// html {
//   zoom: 0.9;
//   -ms-zoom: 0.9;
//   -webkit-zoom: 0.9;
//   -moz-transform: scale(0.9, 0.9);
//   -moz-transform-origin: left center;
// }
// html {
//   font-size: 16px;
// }

// @media only screen and (min-width: 720px) {
//   html {
//       font-size: 18px;
//   }
// }

// @media only screen and (min-width: 960px) {
//   html {
//       font-size: 20px;
//   }
// }

// @media only screen and (min-width: 1240px) {
//   html {
//       font-size: 24px;
//   }
// }

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
section,
span,
a,
p {
  box-sizing: border-box;
}

.flex {
  display: flex;
}
//to keep the scrollbar alawys visible. prevent jerk issues while changing pages
body {
  min-height: 100.06vh;
}
.App {
  background: #f9fbfd;
  min-height: 100vh;
}

// for the body scroll. for customisation use specific css
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 15px;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.padding-15 {
  padding: 15px;
}

.padding-30 {
  padding: 30px;
}

.sp-bw {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
.align-self-center {
  align-self: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.text-align-right {
  text-align: right;
}

button,
.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: $font14;
  font-weight: 500;
  white-space: nowrap;
}

.btn-disabled {
  background-color: $disabled;
  border: 1px solid $disabled;
  color: #8e9093;
  width: 250px;
  align-content: center;
  text-align: center;
  cursor: default;
  pointer-events: none;
}

.btn-primary {
  background-color: $primary;
  color: $white;
  box-shadow: $boxShadow;
  border: 1px solid $primary;
  width: 250px;
  align-content: center;
  text-align: center;
}

.btn-secondary {
  background-color: $secondary;
  color: $primary;
  box-shadow: $boxShadow;
  border: 1px solid $primary;
  width: 250px;
  &:hover {
    background-color: #404e5e;
    border: none;
    color: white;
    transition: 0.4s all ease;
    transform: translate(0, -2px);
  }
}

.content-wrapper {
  width: 75%;
}

.nav-menu {
  width: 4%;
  position: fixed;
  z-index: 1;
  top: 13vh;
  left: 0;
  transition: 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: $boxShadow;
  border-radius: 0px 20px 20px 0px;
  background-color: $secondary;
  max-height: calc(100vh - 9rem);
  height: 80vh;
  &.expand {
    width: 22%;
    padding: 0px 1.25rem 0.625rem 1.2rem;
    a {
      // padding: 20px;
      align-items: center;
      margin: 0.0625rem 0px;
      width: 91.5%;

      .nav-icons {
        margin-right: 10%;
      }
    }
    .dashboard-text {
      // padding: 20px;
      span:nth-child(2) {
        margin-right: auto;
      }
      .nav-icons {
        margin-right: 10%;
      }
      .go-back-icon {
        // margin-left: 40%;
        // margin-right: 10px;
      }
    }
  }
  a {
    flex: 1;
    color: $primary;
    text-decoration: none;
    padding: 0.625rem 1.25rem;
    width: 100%;
    // margin: 0 10px;
    // text-align: left;
    // justify-content: flex-start;
    margin: 0.0625rem 0px;
    opacity: 0.7;
    display: flex;
    &:hover,
    &:focus,
    &:active,
    &.is-active {
      background-color: $selected;
      box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
      border-radius: 5px 30px 30px 5px;
      opacity: 1;
      transition: 0.5s all ease;
    }
  }
  .dashboard-text {
    font-weight: 600;
    color: $primary;
    text-decoration: none;
    // padding: 20px 5px;
    width: 100%;
    margin: 5px;
    // text-align: left;
    // justify-content: flex-start;
    display: flex;
    font-size: 1rem;
    justify-content: center;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
  }
}

.go-back-icon {
  margin-left: 5px;
  &.expanded {
    background: url("../assets/collapse.svg") 100% 100% no-repeat;
  }
  &.collapsed {
    background: url("../assets/expand.svg") 100% 100% no-repeat;
    padding-left: 38px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

.nav-icons {
  margin-right: 0px;
  &.hamburger-icon {
    background: url("../assets/hamburger.svg") 100% 100% no-repeat;
  }
  &.over-view {
    background: url("../assets/overView.svg") 100% 100% no-repeat;
  }
  &.usage-profile {
    background: url("../assets/usageProfile.svg") 100% 100% no-repeat;
  }
  &.health-check {
    background: url("../assets/healthCheck.svg") 100% 100% no-repeat;
  }
  &.remote-monitoring {
    background: url("../assets/remoteMonitoring.svg") 100% 100% no-repeat;
  }
  &.fault-trend {
    background: url("../assets/faultTrend.svg") 100% 100% no-repeat;
  }
  &.perspective-analytics {
    background: url("../assets/prescriptiveAnalytics.svg") 100% 100% no-repeat;
  }
  &.maintainance-overview {
    background: url("../assets/maintainance.svg") 100% 100% no-repeat;
  }
  &.maintainance-logs {
    background: url("../assets/logs.svg") 100% 100% no-repeat;
  }
  &.warranty-management {
    background: url("../assets/warranty.svg") 100% 100% no-repeat;
  }
  &.configure-features {
    background: url("../assets/configure-features.svg") 100% 100% no-repeat;
  }
}

.nav-items {
  color: #353536;
  font-size: 0.875rem;
}

.inline-block {
  display: inline-block;
}

.admin-nav-content.expand > a {
  max-height: 8%;
}
.admin-nav-content-collapse > a {
  max-height: 8%;
}

@media only screen and (max-width: 767.5px) {
  .btn {
    padding: 5px 10px;
    font-size: $font12;
  }
}
@media screen and (min-width: 1100px) {
  .nav-menu a {
    align-items: center;
  }
  .btn-primary {
    font-size: 14px;
    width: 220px;
  }
  .btn-secondary {
    font-size: 14px;
    width: 220px;
  }
}

@media screen and (min-width: 1300px) {
  .nav-menu {
    &.expand {
      padding-bottom: 15px;
    }
    a {
      padding: 12px 1.25rem;
      align-items: center;
    }
  }
  .nav-items {
    font-size: 14px;
  }
  .dashboard-text {
    font-size: 18px;
  }
}

@media screen and (min-width: 1400px) {
  .nav-items {
    font-size: 16px;
  }
  .nav-menu .dashboard-text {
    font-size: 16px;
  }
  .btn-primary {
    font-size: 16px;
    width: 250px;
  }
  .btn-secondary {
    font-size: 16px;
    width: 250px;
  }
}

@media screen and (min-width: 1700px) {
  .nav-menu {
    &.expand {
      padding-bottom: 15px;
    }
    a {
      padding: 12px 1.25rem;
      align-items: center;
    }
  }
  .nav-items {
    font-size: 18px;
  }
  .nav-menu .dashboard-text {
    font-size: 18px;
  }
}
@media screen and (max-height: 640px) {
  .nav-menu {
    &.expand {
      padding-bottom: 0px;
    }
    a {
      padding: 8px 10px;
      align-items: center;
    }
  }
  .nav-items {
    font-size: 14px;
  }
  .dashboard-text {
    font-size: 16px;
  }
}
