.layout__container {
  flex: 1;
  width: 100%;
  background-color: #fdf9ea;
  background-image: url(../../assets/login_left_bg.png);
  min-height: 100%;
  display: flex;
  align-self: stretch;
}
.layout__container--content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .layout__container--logo {
    margin: 20px;
    max-width: 287px;
    max-height: 57px;
    align-self: left;
    flex: 1;
  }
  .layout__container--image {
    width: 100%;
    height: 100%;
    align-self: center;
    flex: 1;
  }
  .layout__container--testimonial {
    align-self: center;
    color: #363636;
    flex: 1;
    p {
      margin: 0px;
      font-family: "Exo";
      font-style: italic;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1;
      text-shadow: 0px 2px 4px rgba(24, 39, 75, 0.12),
        0px 4px 4px rgba(24, 39, 75, 0.08);
      span {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        text-align: end;
        margin-top: 10px;
      }
      span::before {
        background-color: #363636;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 3%;
      }
    }
  }
}
