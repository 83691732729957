.admin_dashboard {
  padding-top: 30px;
  .header_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5%;
    text-align: center;
    .admin_page_card {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-around;
      padding: 0px 20px 0px 20px;
      box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
      border-radius: 5px;
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      .count {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .search_input {
    margin: 20px 0px 10px 0px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 5fr;

    .dropdown_content {
    }
    .search_textfield {
      position: relative;
      display: grid;
      grid-template-columns: 4fr 1fr;
      column-gap: 10px;
      .search_field {
        width: 100%;
        border: 1px solid #cfcfd0;
        border-radius: 5px;
        padding: 10px 30px;
        &:focus {
          border-color: #fcf1cc;
          border-width: 2px;
          transform: 0.5s all ease;
        }
        &:focus-visible {
          outline: none;
        }
      }
      .search_image {
        position: absolute;
        top: 13px;
        left: 10px;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          transition: 0.5s all ease;
        }
      }
    }
    .go_buton {
    }
  }
  .react_date {
    input {
      border: 1px solid #cfcfd0;
      padding: 10px 20px;
      &:focus {
        outline: none;
        border: 1px solid #cfcfd0;
      }
    }
  }
  .users_table {
    table {
      padding: 0px;
      margin-top: 20px;
      box-shadow: 0px 1px 5px rgba(100, 116, 139, 0.12);
      border-radius: 8px;

      td {
        border-left: none;
      }
      thead {
        background-color: #c9ced7;
        tr {
          th {
            background-color: #c9ced7;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          .name {
            width: 18%;
          }
          .mail {
            width: 28%;
          }
          .date {
            width: 18%;
          }
          .status {
            width: 18%;
          }
          .actions {
            width: 18%;
          }
        }
      }
      tbody {
        tr {
          td {
            height: 50px;
            padding: 0px 10px;
            &:nth-child(1) {
              // border-left: 1px solid #c9ced7;
            }
            .action_container {
              display: flex;
              column-gap: 12px;
              div {
                padding: 10px 14px;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                  transform: scale(1.05);
                  transition: 0.5s all ease;
                }
              }
              .confirm_user {
                background-color: #56a64b;
              }
              .edit_user {
                background-color: #ff7104;
              }
              .delete_user {
                background-color: #f23732;
              }
              .view_user {
                background-color: #ffbd03;
              }
              .space_div {
                padding: 10px 20px;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
  .confirm_user_modal {
    margin-top: 30px;
    .modal-container {
      min-width: 600px;
    }
    .user_details {
      .user_keys {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p:nth-child(1) {
          width: 35%;
        }
        p:nth-child(2) {
          width: 55%;
        }
      }
    }
    .button_container {
      display: flex;
      justify-content: flex-end;
      .cancel_button {
        width: auto;
        margin-right: 20px;
      }
      .approve_button {
        width: auto;
      }
    }
  }
  .edit_user_modal {
    .modal-container {
      min-width: 450px;
      width: auto;
      .authorisation_details {
        .radio_group {
          margin-top: 20px;
          input {
            margin-left: 30px;
            margin-right: 8px;
          }
          input:nth-child(1) {
            margin-left: 0px;
          }
        }
        .button_container {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          button {
            width: auto;
          }
          .cancel_button {
            font-size: 14px;
            padding: 9px 20px;
            margin-right: 10px;
          }
          .approve_button {
            font-size: 14px;
            padding: 9px 20px;
          }
        }
      }
    }
  }
  .delete_user_modal {
    .delete_details {
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
      }
      .delete_button_container {
        display: flex;
        justify-content: flex-end;

        button {
          width: auto;
        }
        .cancel_button {
          font-size: 14px;
          padding: 9px 20px;
          margin-right: 10px;
        }
        .approve_button {
          font-size: 14px;
          padding: 9px 20px;
        }
      }
    }
  }
  .remark_modal {
    .remark_heading {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin: 0px;
      margin-bottom: 10px;
    }
    .remarks_text {
      width: 100%;
      border: 1px solid #c9ced7;
      &:focus {
        border: 1px solid #c9ced7;
        outline: none;
      }
    }
    .button_container {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      button {
        width: auto;
      }
      .cancel_button {
        font-size: 14px;
        padding: 9px 20px;
        margin-right: 10px;
      }
      .approve_button {
        font-size: 14px;
        padding: 9px 20px;
      }
    }
  }
}
