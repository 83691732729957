.page-wrapper {
  padding: 0px 30px;
  width: 97%;
  height: 100%;
  box-shadow: 0px 1px 5px rgba(100, 116, 139, 0.12);
  background-color: #fff;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
}
.page-heading {
  font-size: 18px;
  color: #353536;
  margin-bottom: 20px;
}
.page-filter {
  display: flex;
  justify-content: flex-end;
  width: 150px;
}
.page-options-container {
  display: flex;
  justify-content: flex-start;
}
.page-content-header {
  justify-content: space-between;
  padding-top: 20px;
}
.page-chart-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 85%;
}
.chip-holder {
  margin-top: 20px;
}
.value-input {
  margin-right: 20px;
}
.date-selector {
  padding: 10px 10px;
  width: 230px;
}

.legend-wrapper {
  display: flex;
  justify-content: flex-end;
}

.legend {
  display: flex;
  justify-content: flex-end;
}

.color-box {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  &.green {
    background-color: #a9d18e;
  }
  &.yellow {
    background-color: #fdfd62;
  }
  &.red {
    background-color: #f23732;
  }
}

.green-legend,
.yellow-legend,
.red-legend {
  text-align: left;
  margin-top: 10px;
  margin-right: 10px;
}

.color-legend {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
}

.time-icon {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.react-datepicker-wrapper {
  width: auto !important;
  align-self: flex-end;
}

.vehicle-usage-charts {
  align-items: center;
  .highcharts-figure {
    width: 48%;
    margin: 0.5rem;
  }
}

.nav-icons {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

@media screen and (min-width: 1100px) {
  .page-wrapper {
    padding: 0px 30px;
  }
}
@media screen and (min-width: 1700px) {
  .page-wrapper {
    padding: 0px 60px;
  }
}
