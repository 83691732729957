.key-value-holder {
  margin: 10px;
  .dtc-input {
    border: 1px solid #cfcfd0;
    border-radius: 3px;
    padding: 9px 30px;
  }
}
.key-name {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
}
.value-details {
  // width: 60%;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #cfcfd0;
  border-radius: 3px;
  padding: 10px 30px;
}

.dtc-probability-holder {
  display: flex;
  justify-content: space-between;
  .key-value-holder {
    width: 50%;
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-left: 0;
    }
    .key-name {
      width: 60%;
      &.right-name {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
}

.dtc-button-container {
  text-align: center;
}

// .associated-dtc {
//   margin-right: 15px;
// }

.no-details {
  font-size: 18px;
  text-align: center;
}

.dtc-details-wrapper {
  .decription__value_holder,
  .potential_root_cause {
    display: flex;
    .value-details {
      width: 70%;
    }
  }
}

.acc_dtc_container {
  display: flex;
  .dtc_container {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media screen and (max-height: 740px) {
  .dtc-details-wrapper {
    font-size: 14px;
    .key-value-holder {
      margin: 3px;
    }
  }
}
