.fuel_economy {
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  .chart_container {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .zoomed_container {
    position: relative;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 22fr 1fr;
    align-items: center;
    .prev_icon {
      cursor: pointer;
    }
    .zoom_inner {
      position: relative;
    }
    .zoom_close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 4px;
      top: 47px;
      z-index: 2;
    }
    .next_icon {
      cursor: pointer;
      justify-self: right;
    }
  }
  .legend_inner {
    background: #e3f5e3;
    padding: 0px 8px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}
