.component-system-history-service-conditions-table {
    padding: 30px 60px 90px;

    thead, th {
        background-color: #11223673;
        color:#fff; 
        padding: 10px;
        &:nth-child(1) {
            width: 100% !important;
        }
    }

    .view-details-from-table{
        cursor:pointer;
        margin-left:5px;
        text-decoration: underline;
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}

.no-data-found {
    padding: 30px 60px 90px;
}

@media screen and (min-width: 1100px) {
    .component-system-history-service-conditions-table {
        padding: 30px 30px 90px;
    }

    .no-data-found {
        padding: 30px 30px 90px;
    }
}

@media screen and (min-width: 1700px) {
    .component-system-history-service-conditions-table {
        padding: 30px 60px 90px;
    }

    .no-data-found {
        padding: 30px 60px 90px;
    }
}