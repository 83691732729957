.btn-driver-summary {
    margin: 15px 0 15px 0;
}

.maintenance-help-wrapper {
    .maintenance-help {
      background-color: #e3f5e3;
      padding: 10px;
      box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
        0px 18px 88px -4px rgba(24, 39, 75, 0.14);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      font-weight: 600;
      .btn-primary {
        font-size: 10px;
        width: auto;
        padding: 5px;
        margin-left: 10px;
      }
    }
  }

  .service-help-wrapper.driver-behaviour{
    flex-direction: row;
    padding: 10px 0;
  }

  .vehicle-usage-charts {
    .large-charts{
      width: 80%;
      position: relative;
      .modal-close{
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        z-index: 5;
        &.driver-behaviour{
          top: 70px
        }
      }
      .highcharts-figure{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        margin: 0
      }
    }
  }