.vibrational-analytics {
  padding-top: 20px;
  font-size: 14px;
  margin-top: 30px;
  padding-bottom: 200px;
  .heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  .button-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    .left-container {
      padding-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    .v-button {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 14px;
      padding-top: 12px;
      white-space: nowrap;
      background-color: #f9f8f1;
      margin-right: 30px;
      box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .button-modal {
    .modal-container {
      .modal-title {
        background: #f9f8f1;
        display: inline-block;
        box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
          0px 4px 4px -2px rgba(24, 39, 75, 0.08);
        border-radius: 10px;
      }
      .modal-content {
        position: relative;
        .title-box {
          position: relative;
          display: flex;
          text-align: center;
          top: -30px;
          justify-content: center;
          .button-head {
            background: #f9f8f1;
            box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
              0px 4px 4px -2px rgba(24, 39, 75, 0.08);
            border-radius: 10px;
            padding: 16px 20px;
            min-width: 150px;
          }
        }
        .content-box {
          position: relative;
          top: -30px;
          p {
            padding-bottom: 16px;
          }
        }
        .form-container {
          .checkbox-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 27px;
            .input-container {
              display: flex;
              grid-gap: 12px;
            }
          }
        }
        .action-container {
          display: flex;
          justify-content: flex-end;
          grid-gap: 20px;
          padding-top: 100px;
          .v-cancel-button {
            width: auto;
            padding-left: 40px;
            padding-right: 40px;
          }
          .v-analysis-button {
            width: auto;
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
  }
  .error-text {
    padding-top: 2px;
    color: #ee1313;
    font-size: 14px;
    position: absolute;
  }
  .vibrational-analysis-table {
    table {
      th {
        background-color: #949ba4;
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
      }
      th:nth-child(1) {
        width: 10%;
      }
      th:nth-child(2) {
        width: 10%;
      }
      th:nth-child(3) {
        width: 10%;
      }
      th:nth-child(4) {
        width: 13%;
      }
      th:nth-child(5) {
        width: 13%;
      }
      th:nth-child(6) {
        width: 14%;
      }
      th:nth-child(7) {
        width: 10%;
      }
      th:nth-child(8) {
        width: 18%;
      }
      .part-name {
        font-weight: 600;
      }
      .measurement {
        padding: 0px;
        .inner {
          padding: 10px;
          border-bottom: 2px solid #f1f1f1;
        }
        .inner:nth-child(3) {
          border-bottom: none;
        }
      }
      .rul {
        padding: 0px;
        .inner {
          padding: 10px;
        }
      }
      .service {
        padding: 0px;
        .inner {
          padding: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .vibrational-analytics {
    font-size: 16px;
    .button-container {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-height: 640px) {
  .vibrational-analytics
    .button-modal
    .modal-container
    .modal-content
    .action-container {
    padding-top: 40px;
  }
}
