@import "../../../scss/colors";

.self-test-container {
  padding: 2rem 5rem;
  margin-top: 20px;
}
.self-test-container .select-container {
  max-width: unset;
}
.select-container-holder {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.self-test-card-container {
  width: 200px;
  text-align: center;
  .self-test-heading {
    margin-bottom: 20px;
  }
  .self-test-card-box {
    background: #f4eefd;
    border: 1px solid #cfcfd0;
    border-radius: 10px;
    height: 20rem;
    padding: 50px 0;
  }
  .self-test-card-wrapper {
    padding: 0 50px;
    height: 100%;
    overflow-y: auto;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    // &::-webkit-scrollbar { /* Chrome */
    //   display: none;
    // }
  }
  .self-test-card {
    margin-bottom: 20px;
    background-color: #f9f9fa;
    padding: 8px 12px;
    border-radius: 3px;
    box-shadow: 0px 4px 8px rgba(14, 77, 164, 0.2);
    &:last-child {
      margin-bottom: 0;
    }
  }
}
