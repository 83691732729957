@import url("./_normalize.scss");
@import url("./_common.scss");
@import url("./utlis.scss");

.container {
  padding: 30px 0px 0px;
}
.full-width {
  width: 100%;
}
.nav-content {
  width: 25%;
}

.search-field {
  min-width: 20rem;
  max-width: 35rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  input {
    padding: 8px 20px;
    line-height: 26px;
    border-radius: 10px;
    outline: none;
    background: transparent;
    border: 1px solid #11223699;
    &:focus {
      border-color: #fcf1cc;
      border-width: 2px;
      transform: 0.5s all ease;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0;
  padding: 30px;
  thead,
  th {
    background-color: #fcf1cc;
    &:nth-child(1),
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 40%;
    }
  }
  th,
  td {
    padding: 15px;
    text-align: left;
    border-left: 1px solid #e5e4e7;
    border-top: 1px solid #e5e4e7;
    &:last-child {
      // border-right: 1px solid #e5e4e7;
      border-right: none;
    }
  }
  tbody tr:last-child td {
    border-bottom: none;
  }
  tbody tr {
    &:hover {
      background-color: #f1f0f3;
      transition: 0.5s all ease;
    }
  }
  th {
    &:nth-child(1) {
      border-radius: 8px 0px 0px 0px;
    }
    &:nth-last-child(1) {
      border-radius: 0px 8px 0px 0px;
    }
  }
  thead {
    tr {
      box-shadow: 0px 1px 5px rgba(100, 116, 139, 0.12);
    }
  }
}
.content {
  width: 75%;
  padding-bottom: 50px;
  margin-left: 23%;
  margin-right: 2%;
  min-width: calc(100vh - 400px);
  min-height: 75vh;
  &.expand {
    width: 95%;
    margin-left: 6%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
