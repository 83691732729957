.driver-summary-wrapper {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  position: relative;
  .driver-summary-card {
    height: 60px;
    box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%),
      0px 8px 8px -8px rgb(24 39 75 / 14%);
    border-radius: 2px;
    width: 100%;
    max-width: 150px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    img {
      width: 20px;
    }
    &:hover {
      .driver-summary-tooltip {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .driver-summary-text {
    font-size: 12px;
    max-width: 150px;
  }
  .driver-summary-tooltip {
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    display: none;
    bottom: -15px;
    left: 50%;
    padding: 3px 8px 8px 8px;
    font-size: 12px;
    z-index: 10;
    white-space: nowrap;
    box-shadow: 0px 8px 8px -6px rgb(24 39 75 / 12%),
      0px 8px 8px -8px rgb(24 39 75 / 14%);
    > div {
      padding-top: 5px;
    }
  }
  &:nth-child(4n) {
    .driver-summary-tooltip {
      left: unset;
      right: 50%;
    }
  }
}

.summary-driver-behaviour {
  background-color: #fcf1cc;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #353536;
}

.driver-behaviour-summary-wrapper {
  padding: 10px 60px;
  box-sizing: border-box;
  .service-help-wrapper {
    padding: 10px 0;
  }

  .page-chart-container.vehicle-usage-charts {
    justify-content: flex-start;
  }
}

.service-help .btn-primary.view-button {
  padding: 7px 15px;
}
