.service-guide-tabs {
  .tabs {
    width: auto;
    padding: 10px 15px;
  }
}

.serviceguide {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
  .dtc_heading_row {
    display: flex;
    align-items: center;
    .live_chat_button {
      cursor: pointer;
      padding: 10px 16px;
      background-color: #f9f9fa;
      border: 1px solid #f9f9fa;
      box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
      display: flex;
      align-items: center;
      grid-gap: 10px;
      .red-dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
  .dtc_heading_container {
    width: 85%;
    text-align: center;
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #353536;
    }
  }
  .content_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5%;
  }
  .large_content_container {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 20px;
    .close_container {
      text-align: right;
      padding-bottom: 4px;
      cursor: pointer;
    }
    .large_container {
      box-shadow: 12px 12px 24px -2px rgba(24, 39, 75, 0.2);
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      .image_container {
        img {
          width: 100%;
          height: 400px;
        }
      }
      .video_container {
        div {
          width: 100% !important;
        }
      }
    }
    .sidebar_container {
      display: grid;
      flex-direction: column;
      grid-gap: 20px;
    }
  }
  .service_card_container {
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(100, 116, 139, 0.12);
    border-radius: 8px;
    width: 100%;
    img {
      width: 100%;
      height: 200px;
      border-radius: 8px 8px 0px 0px;
    }
    p {
      padding: 12px;
      margin: 0px;
    }
    .video_container {
      width: 100%;
      height: 200px;
      div {
        width: 100% !important;
        height: 200px !important;
      }
    }
    .text_container {
      padding-bottom: 12px;
      .short_desc_text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 72px;
        line-height: 20px;
      }
    }
  }
  .chat-bot {
    position: absolute;
    right: 1%;
    bottom: 15%;
    cursor: pointer;
  }
  .chat-image {
    position: absolute;
    right: 1%;
    bottom: 8%;
  }
}
