@import "../../scss/colors";

.header {
  padding: 10px 1.2rem;
  background-color: $primary;
  color: $white;
  font-size: $font14;
  z-index: 11;
  width: 100%;
  position: sticky;
  top: 0;
  height: 10vh;
  // box-shadow: 1px 1px 5px #112135, -1px -1px 5px #112337;
  box-shadow: 5px 5px 10px #c3c5c8, -5px -5px 10px #cbcdd0;
  // height: 70px;
}
.header-logo {
  img {
    // width: 227px;
    // height: 45px;
    height: 7vh;
  }
  cursor: pointer;
  margin: 5px 0px;
}

.icon-and-image-container {
  // padding: 10px 20px;
  font-family: "Open Sans" !important;
  cursor: pointer;
}

.icon {
  height: 20px;
  width: 20px;
}

.header-search {
  width: 20rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  input {
    padding: 10px 20px;
    border-radius: 10px;
    outline: none;
    background: transparent;
    color: $white;
    border: 1px solid $white;
    &:focus {
      border-color: #fcf1cc;
      border-width: 1.5px;
      transform: 0.4s all ease;
    }
  }
}

.search-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
}

.navigation-container {
  width: 450px;
}

@media only screen and (max-height: 767.5px) {
  .header-logo {
    img {
      position: relative;
      top: -5px;
    }
  }

  .navigation-container {
    width: 20%;
    .text {
      padding-top: 6px;
    }
  }
}
