@import "../../../scss/colors";
.modal-wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

@keyframes showModal {
  from {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.animated {
  animation: showModal 0.2s forwards;
}

.close-animated {
  animation: showModal 0.2s reverse;
}
.modal-container {
  background-color: $white;
  padding: 16px;
  max-height: 80vh;
  box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
  border-radius: 6px;
  overflow-y: auto;
  &.small {
    width: 400px;
  }
  &.medium {
    width: 600px;
  }
  &.large {
    width: 800px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
  }
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;
}
.modal-title {
  width: 100%;
  color: $primary;
  font-size: $font18;
  font-weight: 600;
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.error {
    color: $error;
  }
}
.modal-close {
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
.dtc-wrapper {
  margin-bottom: 10px;
}
.dtc-heading {
  font-size: 16px;
  color: #000;
  flex-basis: 30%;
}
.dtc-value {
  flex-basis: 70%;
  &.small {
    input {
      width: 55px;
    }
  }
  input {
    font-size: 16px;
    color: #000;
    padding: 8px 20px;
    border: 1px solid #cfcfd0;
    border-radius: 3px;
    &.full-width {
      width: 90%;
    }
  }
}
.dtc-button-container {
  button:first-child {
    margin-right: 24px;
  }
}
.model-container p {
  font-size: 14px;
}

@media screen and (min-width: 1700px) {
  .model-container p {
    font-size: 16px;
  }
}

.main-diagnostics-modal {
  .modal-close {
    img {
      position: relative;
      left: 10px;
      top: -10px;
    }
  }
}

// @media screen and (max-height: 740px) {
//   .modal-container {
//     &.large {
//       width: 80%;
//     }
//   }
// }
