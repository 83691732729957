.highcharts-figure {
    width: 48%;
    margin: 0.5rem;
    &.full-width{
        width: 100%;
    }
}
.highcharts-title{
    font-size: 12px !important;
}
