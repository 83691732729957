.filter-wrapper {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}
.filter-options-container {
  position: relative;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f9f9fa;
  }
}

.filter-wrapper {
  position: absolute;
  top: 0;
  left: -10rem;
  right: 0;
  z-index: 10;
  background: #f9f9fa;
  max-height: 200px;
  overflow-y: scroll;
}

.filter-icon {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.filter-container {
  cursor: pointer;
  padding: 10px 30px;
  font-size: 18px;
  background-color: #f9f9fa;
  border: 1px solid #f9f9fa;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  display: flex;
  align-items: center;
}

.page-filter-wrapper {
  align-self: flex-end;
}

@media screen and (min-width: 1100px) {
  .filter-container {
    font-size: 14px;
  }
  .filter-wrapper {
    font-size: 14px;
  }
}
@media screen and (min-width: 1400px) {
  .filter-container {
    font-size: 16px;
  }
  .filter-wrapper {
    font-size: 16px;
  }
}
