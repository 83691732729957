.notification-table {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 20px;

  color: #112236;
  table {
    padding: 30px 0px;
    td {
      border-left: none;
    }
    th {
      border-left: 1px solid #f7edc9;
      &:nth-child(1) {
        width: 10%;
        border-left: 1px solid #c4c4c4;
      }
      &:nth-child(2) {
        width: 55%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 15%;
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(1) {
            border-left: 1px solid #c4c4c4;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .notification-table {
    font-size: 16px;
  }
}
