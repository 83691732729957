@import "../../scss/colors";
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,500;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
.login {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;

  .login__Form {
    flex: 1;
    font-family: "Open Sans";
    width: 100%;
    background-color: #ffffff;
    padding: 25px 40px;
    overflow: auto;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    .login__rightside__container {
      width: 100%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #363636;
      .login__Form--title {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 1.5;
          color: #2e2f30;
          padding-bottom: 20px;
        }
      }

      .login__Form--footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span:first-child {
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.5;
          margin: 15px auto;
        }
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.5;
          color: $primary;
          a {
            text-decoration: none;
            color: $primary;
            &:hover {
              color: #000000;
            }
          }
        }
      }
      .login__Form--forgettext {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.5;
          color: #747577;
          cursor: pointer;
          &:hover {
            color: #000000;
          }
        }
      }
      .login__Form--btn {
        margin: 10px auto;
        width: 100%;
        button {
          border-radius: 15px;
          width: 100%;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5;

          &:hover {
            background-color: #000000;
            -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
            -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
            transition: all 0.4s ease 0s;
          }
        }
      }
      .login__Form--inputs {
        width: 100%;

        input {
          border-radius: 15px;
          width: 100%;
        }
      }
    }
  }
}
.forgotpwd__Form {
  width: 100%;
  &--inputs {
    width: 100%;
    input {
      width: 100%;
      border-radius: 5px;
      padding: 5px !important;
    }
    button {
      border-radius: 5px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
      padding: 5px 30px;
      &:hover {
        background-color: #000000;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s;
      }
    }
  }
  .forgotpwd__Form--title {
    p {
      margin: 0px 0px 10px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 150%;
      color: $primary;
    }
  }
  .forgotpwd__Form--footer {
    p {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      text-decoration-line: underline;
      color: $primary;
      span {
        cursor: pointer;
      }
    }
  }
}
.rsetpwd__Form {
  width: 100%;
  &--inputs {
    width: 100%;
    input {
      width: 100%;
      border-radius: 5px;
      padding: 5px !important;
    }
    button {
      border-radius: 5px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
      padding: 5px 30px;
    }
  }
  .rsetpwd__Form--title {
    margin-bottom: 10px;
    p {
      margin: 0px 0px 10px 0px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.5;
      color: $primary;
    }
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #747577;
    }
  }
  .rsetpwd__Form--btn {
    display: flex;
    justify-content: flex-end;
    button {
      width: auto;
      &:hover {
        background-color: #000000;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s;
      }
    }
    button:first-of-type {
      margin-right: 10px;
      &:hover {
        color: #ffffff;
        background-color: #000000;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s;
      }
    }
  }
}
.Form--error {
  color: $error;
  display: flex;
  justify-content: center;
}
.Form--success {
  color: green;
  display: flex;
  justify-content: center;
}
::-ms-reveal {
  display: none;
}
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
