.performance-digital-twin-page-wrapper {
  margin-top: 20px;
  width: 100%;
  padding: 0px 60px;
}

.no-data {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}

.digital-twin-charts {
  align-items: center;
  .large-charts {
    width: 80%;
    position: relative;
    .modal-close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
      z-index: 5;
      &.driver-behaviour {
        top: 70px;
      }
    }
    .highcharts-figure {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      margin: 0;
    }
  }
}

@media screen and (min-width: 1100px) {
  .performance-digital-twin-page-wrapper {
    padding: 0px 30px;
  }
}
