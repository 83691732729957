.highcharts-figure {
    width: 30%;
    margin: 0.5rem;
}

.figure-large-container{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    height: 100%;

    .modal-close{
        position: absolute;
        top: 20px;
        right:5px;
        z-index: 5;
    }

    .highcharts-figure-large{
        margin: 1em 0em;
        height: 100%;
    }

    .figurre-content{
        height: 100%;
    }
}
