.quality-table{
    padding: 30px 0 0;

    thead, th {
        background-color: #11223673;
        color:#fff;
        width: 20% !important; 
        padding: 10px;
    }

    .pagination-container{
        margin-bottom: 30px;
    }
}