.realtime-gauge-select {
  .option-value {
    padding: 10px 16px;
  }
  .option-box0 {
    border-bottom: 0.5px solid gray;
  }
}
.chart-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .unit-container {
    flex: 0.9 1;
    display: flex;
    align-items: center;
    margin-top: 41px;
    justify-content: center;
    font-family: "Digi";
    font-weight: 300;
    font-size: 36px;
    line-height: 46px;
    color: #353536;
    letter-spacing: -0.065px;
  }
  .chart-text-container {
    h2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #353536;
    }
  }
}

.realtime-graph {
  .graph {
    > div {
      box-shadow: 0px 1px 4px rgba(100, 116, 139, 0.12);
    }
  }
}
