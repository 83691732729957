input {
  box-sizing: border-box;
}
.input__field {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  input {
    border: 0.5px solid #8e9093;
    border-radius: 5px;
    line-height: 26px;
    outline: none;
    padding: 10px;
    margin: 5px 0px;
    color: #747577;
  }

  svg {
    height: 20px;
    position: absolute;
    right: 10px;
    width: 20px;
  }
}
.input__container {
  margin-bottom: 10px;
  span {
    padding-left: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #f23732;
  }
}
.input__field--mobile {
  border: 0.5px solid #8e9093;
  border-radius: 15px;

  input {
    border: 0;
    line-height: 26px;
    outline: none;
    padding: 5px;
    margin: 5px 5px;
    color: #747577;
  }
  input:first-of-type {
    border-right: 0.5px solid #cccccc;
    border-radius: 0px !important;
    width: 40px !important;
  }
}
