.sec-tabs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px 60px;
}
.digital-twin-container {
  margin-top: 30px;
}
.sec-tabs {
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 6px;
  width: 250px;
  align-content: center;
  text-align: center;
  border: none;
  outline: none;
}

.btn-primary.sec-tabs {
  background-color: #fcf1cc;
  color: #000000;
}
