.warranty-management-container {
  margin-top: 10px;
  .diagnostics-container {
    .sec-tabs {
      width: auto;
    }
    .diagnostics-topdtc-complaints {
      padding: 30px 30px;
      margin-top: 20px;
      .first-container {
        display: flex;
        grid-gap: 80px;
        padding-top: 0px;
      }
      .second-container {
        display: flex;
        padding-top: 58px;
        grid-gap: 80px;
      }
    }
  }
  .warranty-rate {
    margin-top: 30px;
    padding-bottom: 20px;
    .first-container {
      display: flex;
      grid-gap: 80px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .sec-tabs {
    width: 220px;
  }
  .warranty-management-container {
    .diagnostics-container {
      .diagnostics-topdtc-complaints {
        padding: 30px 30px;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .sec-tabs {
    width: 250px;
  }
  .warranty-management-container {
    .diagnostics-container {
      .diagnostics-topdtc-complaints {
        padding: 30px 60px;
      }
    }
  }
}
