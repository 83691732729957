.fault_trend_pagewrapper {
  padding: 0px 30px !important;
}
.go-button {
  max-width: 120px;
  height: 40px;
  align-self: flex-end;
}
.predicted-dtc-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #353536;
  margin-bottom: 25px;
}

.low-rul {
  display: inline-block;
  vertical-align: middle;
  width: 55px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #353536;
  transform: rotate(-90deg);
  text-align: center;
}

.high-rul {
  display: inline-block;
  vertical-align: middle;
  width: 55px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #353536;
  transform: rotate(-90deg);
  text-align: center;
  margin-left: -15px;
}

.predicted-dtc-wrapper,
.selected-dtc-name-number {
  padding-top: 40px;
}

.left-arrow-wrapper,
.right-arrow-wrapper {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #c4c4c4;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: auto;
  }
}

.dtc-numbers-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: -15px;
}

.dtc-numbers-ul {
  margin: 0;
  padding: 0;
}

.dtc-numbers-li {
  display: inline-block;
  cursor: pointer;
  height: 55px;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  padding: 15px 10px;
  color: #353536;
  background-color: #fcf1cc;
  box-shadow: 0px 4px 8px rgba(14, 77, 164, 0.2);
  box-sizing: border-box;
  border: 1px solid #75777c;
  filter: drop-shadow(0px 4px 8px rgba(14, 77, 164, 0.2));
  &.active {
    font-weight: 400;
    color: #ffffff;
    background-color: #112236;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.fault-trend-input {
  width: 120px;
  text-align: right;
  height: 35px;
  font-size: 14px;
  align-self: flex-end;
}

.show-details-button-fault {
  width: 150px;
  margin-left: 50px;
}

.dtc-name-number {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #353536;
}

.fault-detail-container {
  border-radius: 8px;
}

.fault-description-wrapper,
.fault-sub-system-wrapper {
  font-size: 14px;
  line-height: 1.25;
  color: #353536;
  padding: 10px;
  .heading {
    font-weight: 600;
  }
  .pointers {
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.pointers-wrapper {
  margin: 0;
  margin-top: 10px;
}

.fault-description-wrapper {
  background-color: #f1f6ff;
  margin-top: 10px;
  border-radius: 8px 8px 0px 0px;
}

.sub-systems {
  background-color: #f4fff4;
}

.remaining-useful-life {
  background-color: #fff4f5;
  border-radius: 0px 0px 8px 8px;
}

.rul-items {
  margin-bottom: 15px;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .item-name {
    width: 25%;
    text-align: left;
  }

  .item-value {
    width: 10%;
    text-align: left;
  }

  .go-button {
    width: auto;
    padding: 5px 15px;
    height: 35px;
    align-self: flex-end;
  }
}

.analyticgraph--top {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 10px 0px;

  span {
    font-weight: 600;
    margin: 0px 5px;
    span {
      font-weight: 400;
    }
  }
}
.analyticgraph--bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 0px;
}
.fault-trend-modal {
  .modal-container {
    width: 566px;
    overflow-x: hidden;
    .modal-close {
      img {
        position: relative;
        left: 14px;
        top: -12px;
      }
    }
    .modal-body {
      position: relative;
      left: 17px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .fault-description-wrapper,
  .fault-sub-system-wrapper {
    font-size: 14px;
  }
  .predicted-dtc-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1400px) {
  .fault-description-wrapper,
  .fault-sub-system-wrapper {
    font-size: 16px;
  }
  .predicted-dtc-text {
    font-size: 18px;
  }
}

@media screen and (max-height: 767px) {
  .fault-trend-modal {
    .modal-container {
      max-height: 90vh;
    }
  }
}
